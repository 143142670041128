import { UAParser } from "ua-parser-js";

const webShareApiDeviceTypes = ["mobile", "smarttv", "wearable"];
const parser = new UAParser();
const browser = parser.getBrowser();
const device = parser.getDevice();

const attemptShare = (shareData) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf("FIREFOX") === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? "") !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  );
};

export const share = (textToShare) => {
  return new Promise((resolve) => {
    const shareData = { text: textToShare };

    let shareSuccess = false;

    try {
      if (attemptShare(shareData)) {
        navigator.share(shareData);
        shareSuccess = true;
      }
    } catch (error) {
      shareSuccess = false;
    }

    if (!shareSuccess) {
      navigator.clipboard.writeText(textToShare);
      resolve();
    }
  });
};
