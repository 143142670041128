import dayjs from "dayjs";

export default class NiyamUtils {
  #NIYAM_DB_KEY = "niyam_db";
  #selectedNiyams;
  niyamChangeCount = 0;
  stats = { currentStreak: 0, bestStreak: 0, count: 0, tries: [] };

  constructor() {
    try {
      const { selected, changeCount, stats } = JSON.parse(
        localStorage.getItem(this.#NIYAM_DB_KEY),
      ) ?? {
        selected: [],
        changeCount: { count: 0, date: this.#getTodayDate() },
        stats: { currentStreak: 0, bestStreak: 0, count: 0, tries: [] },
      };
      this.#selectedNiyams = selected ?? [];
      if (changeCount && this.#getTodayDate() === changeCount.date) {
        this.niyamChangeCount = changeCount.count;
      }
      if (stats) {
        this.stats = stats;
      }
    } catch (e) {
      this.#selectedNiyams = [];
      this.niyamChangeCount = 0;
    }
  }

  getTodaysNiyam() {
    const todayDate = this.#getTodayDate();
    return this.#selectedNiyams.find(({ date }) => date === todayDate) ?? {};
  }

  isSelectedInPast(newNiyam) {
    return !!this.#selectedNiyams.find(({ niyam }) => niyam === newNiyam);
  }

  confirmNiyam(niyam) {
    this.addNiyam(niyam, true);
  }

  addNiyam(niyam, confirmed) {
    const todayDate = this.#getTodayDate();
    // this condition will be true when user tries to add niyam again for same date
    if (this.#selectedNiyams.at(-1)?.date === todayDate) {
      this.#selectedNiyams.pop();
    }
    this.#selectedNiyams.push({ niyam, date: todayDate, confirmed });
    // we are only keeping track of last 7 niyam choose by user
    this.#selectedNiyams = this.#selectedNiyams.slice(-7);
    if (confirmed) {
      this.stats.count = (this.stats.count ?? 0) + 1;
      if (this.#selectedNiyams.at(-1)?.date === this.#getLastDate()) {
        this.stats.currentStreak++;
      } else {
        this.stats.currentStreak = 1;
      }
      if (this.stats.bestStreak < this.stats.currentStreak) {
        this.stats.bestStreak = this.stats.currentStreak;
      }
      if (!this.stats.tries[this.niyamChangeCount]) {
        this.stats.tries[this.niyamChangeCount] = 0;
      }
      this.stats.tries[this.niyamChangeCount]++;
    } else {
      this.niyamChangeCount++;
    }
    this.#saveToDB();
  }

  #saveToDB() {
    window.localStorage.setItem(
      this.#NIYAM_DB_KEY,
      JSON.stringify({
        selected: this.#selectedNiyams,
        changeCount: {
          date: this.#getTodayDate(),
          count: this.niyamChangeCount,
        },
        stats: this.stats,
      }),
    );
  }

  #getTodayDate() {
    return this.#formatDate(new Date());
  }

  #getLastDate() {
    return this.#formatDate(dayjs().subtract(1, "day").toDate());
  }

  #formatDate(date) {
    return date.toISOString().substring(0, 10);
  }
}
