import React, { useEffect, useState } from "react";
import ShareNiyam from "./ShareNiyam";
import { useTranslation } from "react-i18next";
import { useGetNewNiyam } from "../hooks/useGetNewNiyam";
import { Loader } from "./Loader";

export default function TakeNiyamSection({ onNiyamSelect, niyamUtils }) {
  const [niyam, setNiyam] = useState();
  const { t } = useTranslation();
  const [niyamConfirmed, setNiyamConfirmed] = useState(false);
  const [getNewNiyam, { loading }] = useGetNewNiyam(niyamUtils);

  useEffect(() => {
    const { niyam, confirmed } = niyamUtils.getTodaysNiyam();
    setNiyam(niyam);
    setNiyamConfirmed(confirmed);
  }, [niyamUtils]);

  const confirmNiyam = () => {
    niyamUtils.confirmNiyam(niyam);
    setNiyamConfirmed(true);
    onNiyamSelect();
    if (window.gtag) {
      window.gtag("event", "confirm_niyam", {
        tries: niyamUtils.niyamChangeCount,
      });
    }
  };

  const triesRemaining = 3 - niyamUtils.niyamChangeCount;

  const changeNiyam = async () => {
    getNewNiyam().then((niyam) => {
      setNiyam(niyam);
      if (triesRemaining === 1) {
        confirmNiyam();
      } else {
        if (window.gtag) {
          window.gtag("event", "change_niyam", {
            tries: niyamUtils.niyamChangeCount,
          });
        }
      }
    });
  };

  return (
    <div className="flex flex-col m-4 justify-center items-center text-center mt-28">
      {!niyam ? (
        <button
          onClick={changeNiyam}
          className="bg-primary p-4 text-xl text-white rounded m-4"
        >
          {t("take_niyam")}
        </button>
      ) : (
        <>
          <div className="font-bold text-3xl">{t("niyam_for_today_is")}</div>
          <div className="text-xl my-8 border-primary border-2 py-16 px-8 rounded-2xl">
            {niyam}
          </div>
          <div className="flex items-center">
            {!niyamConfirmed && (
              <button
                onClick={confirmNiyam}
                className="bg-primary p-4 text-xl text-white rounded m-4"
              >
                {t("confirm_niyam")}
              </button>
            )}
            {niyamConfirmed && <ShareNiyam niyam={niyam} />}
          </div>
          {triesRemaining > 0 && !niyamConfirmed && (
            <button
              onClick={changeNiyam}
              className="text-blue-500 m-4 underline"
            >
              {t("change_niyam", { triesRemaining })}
            </button>
          )}

          {loading && <Loader />}
        </>
      )}
    </div>
  );
}
