import React, { useState } from "react";
import { AiFillSetting, AiOutlineInfoCircle } from "react-icons/ai";
import { IoIosStats } from "react-icons/io";
import StatsModal from "../modals/StatsModal";
import InfoModal from "../modals/InfoModal";
import { useTranslation } from "react-i18next";
import SettingsModal from "../modals/SettingsModal";

export default function Navbar({ niyamUtils }) {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showStatsModal, setStatsModal] = useState(false);
  const [showSettingsModal, setSettingsModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <nav className="flex m-4 justify-between items-center font-bold pb-2 border-b-2">
        <button
          onClick={() => {
            setShowInfoModal(true);
          }}
        >
          <AiOutlineInfoCircle className="text-blue-600 text-xl" />
        </button>
        <div className="text-xl">{t("app_name")}</div>
        <div className="flex gap-2">
          <button
            onClick={() => {
              setStatsModal(true);
            }}
          >
            <IoIosStats className="text-blue-600 text-xl" />
          </button>
          <button
            onClick={() => {
              setSettingsModal(true);
            }}
          >
            <AiFillSetting className="text-blue-600 text-xl" />
          </button>
        </div>
      </nav>
      <InfoModal
        open={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}
      />
      <StatsModal
        niyamUtils={niyamUtils}
        open={showStatsModal}
        onClose={() => {
          setStatsModal(false);
        }}
      />
      <SettingsModal
        open={showSettingsModal}
        onClose={() => {
          setSettingsModal(false);
        }}
      />
    </>
  );
}
