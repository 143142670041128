import { share } from "../utils/share-utils";
import { useTranslation } from "react-i18next";
import { BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";

export default function ShareNiyam({ niyam }) {
  const { t } = useTranslation();
  return (
    <button
      className="border-2 border-primary p-2 px-4 text-primary rounded m-8"
      onClick={() => {
        share(t("share_text", { niyam, url: window.location.href })).then(
          () => {
            alert("Messaged copied");
          },
        );
        if (window.gtag) {
          window.gtag("event", "share_niyam");
        }
      }}
    >
      <div className="flex gap-2 items-center">
        <span>{t("share_btn")}</span>
        <BsWhatsapp className="text-green-600" />
        <BsTwitter className="text-blue-500" />
        <BsFacebook className="text-blue-600" />
      </div>
    </button>
  );
}
