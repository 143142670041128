import Modal from "../components/Modal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getUserLocale, setUserLocale } from "../utils/locale-utils";

const languages = [
  { code: "en", name: "English" },
  { code: "hi", name: "हिंदी" },
];

export default function SettingsModal({ open, onClose }) {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(() => getUserLocale());

  const selectLanguage = (code) => {
    setLanguage(code);
    i18n.changeLanguage(code);
    setUserLocale(code);
    if (window.gtag) {
      window.gtag("event", "change_language", {
        code,
      });
    }
  };

  return (
    <Modal title={t("modal.settings.title")} show={open} onClose={onClose}>
      <div>
        <div className="mb-2">{t("modal.settings.select_language")}</div>
        <div className="flex gap-4">
          {languages.map(({ code, name }) => (
            <button
              key={code}
              className={`${
                code === language ? "bg-primary text-white" : "bg-gray-200"
              } px-4 py-2 rounded-xl`}
              onClick={() => {
                selectLanguage(code);
              }}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}
