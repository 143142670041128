import { useState } from "react";
import { getUserLocale } from "../utils/locale-utils";

export const useGetNewNiyam = (niyamUtils) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getNiyam = (tryCount = 0) => {
    const locale = getUserLocale();
    setLoading(true);
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_CMS_URL}/api/niyam/get-random?locale=${locale}`,
      )
        .then((response) => response.json())
        .then((response) => {
          const newNiyam = response?.data?.attributes?.niyam;
          if (tryCount < 3 && niyamUtils.isSelectedInPast(newNiyam)) {
            getNiyam(tryCount + 1)
              .then((newNiyam) => {
                niyamUtils.addNiyam(newNiyam);
                resolve(newNiyam);
              })
              .catch((error) => reject(error));
          } else {
            niyamUtils.addNiyam(newNiyam);
            resolve(newNiyam);
          }
        })
        .catch((error) => {
          setError(error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return [getNiyam, { loading, error }];
};
