import Modal from "../components/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function StatsModal({ niyamUtils, open, onClose }) {
  const [stats, setStats] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStats(niyamUtils.stats);
  }, [niyamUtils.stats]);

  return (
    <Modal title={t("modal.stats.title")} show={open} onClose={onClose}>
      <div>
        <div className="flex justify-evenly">
          <div className="text-center">
            <div className="text-3xl">{stats.currentStreak}</div>
            <div>{t("modal.stats.current_streak")}</div>
          </div>
          <div className="text-center">
            <div className="text-3xl">{stats.bestStreak}</div>
            <div>{t("modal.stats.best_streak")}</div>
          </div>
        </div>
        <div className="text-center text-xl my-4">
          {t("modal.stats.heading")}
        </div>
        <div>
          {[1, 2, 3].map((index) => {
            let count;
            try {
              count = stats.tries[index] ?? 0;
            } catch {
              count = 0;
            }
            return (
              <div key={index} className="flex items-center">
                {index}
                <div
                  className="ml-1 bg-primary text-center my-0.5 rounded"
                  style={{
                    width:
                      ((count || 0.1) / (Math.max(stats.tries) || 0.1)) * 100,
                  }}
                >
                  {count}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
