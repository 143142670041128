import React from "react";

export default function Modal({ title, children, onClose, onSubmit, show }) {
  if (!show) {
    return null;
  }
  return (
    <div className="items-center justify-center h-screen w-screen fixed top-0 bg-black bg-opacity-60">
      <div className=" h-screen">
        <div className="bg-white rounded-md w-3/4 lg:w-1/4 right-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="p-3 flex items-center justify-between border-b border-b-gray-300">
            <h3 className="font-semibold text-xl">{title}</h3>
            <span className="modal-close cursor-pointer" onClick={onClose}>
              ×
            </span>
          </div>
          <div className="p-3">{children}</div>
          {onSubmit && (
            <div className="p-2 flex justify-end border-t border-t-gray-300">
              <button
                className="text-sm text-white bg-blue-500 rounded-md px-4 py-2"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
