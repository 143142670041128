import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import TakeNiyamSection from "./components/TakeNiyamSection";
import ReactConfetti from "react-confetti";
import NiyamUtils from "./utils/niyam-utils";

function App() {
  const [niyamUtils] = useState(() => new NiyamUtils());
  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showConfetti, setShowConfetti] = useState(false);

  const onNiyamSelect = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };

  const detectSize = () => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, []);

  return (
    <>
      <Navbar niyamUtils={niyamUtils} />
      <TakeNiyamSection onNiyamSelect={onNiyamSelect} niyamUtils={niyamUtils} />
      {showConfetti && (
        <ReactConfetti
          width={windowDimension.width}
          height={windowDimension.height}
          tweenDuration={1000}
        />
      )}
    </>
  );
}

export default App;
