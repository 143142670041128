import Modal from "../components/Modal";
import { useTranslation } from "react-i18next";

export default function InfoModal({ open, onClose }) {
  const { t } = useTranslation();
  return (
    <Modal title={t("modal.info.title")} show={open} onClose={onClose}>
      <div>
        <ul className="list-disc ml-4">
          {t("modal.info.points", { returnObjects: true }).map(
            (point, index) => (
              <li key={index}>{point}</li>
            ),
          )}
        </ul>
        <hr className="my-2" />
        <p className="text-sm">
          {t("modal.info.footer")}{" "}
          <a
            className="text-blue-600"
            href="https://jinswara.com/"
            target="_blank"
            rel="noreferrer"
          >
            JinSwara.com
          </a>
        </p>
      </div>
    </Modal>
  );
}
